<template>
    <section class="invoice-add-wrapper">
        <b-row class="invoice-add" style="margin-bottom:0;padding-bottom:0;">
            <!-- Col: Left (Invoice Container) -->
            <b-col cols="12" style="margin-bottom:0;padding-bottom:0;">
                <b-card no-body class="invoice-preview-card" style="margin-bottom:0;padding-bottom:0;">
                    <!-- Header -->
                    <b-card-body class="invoice-padding pb-0" style="margin-bottom:0;padding-bottom:0;">
                        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0" style="margin-bottom:10px;">
                            <!-- Header: Left Content -->
                            <div style="text-align:left;">
                                <div class="logo-wrapper mb-0">
                                    <h3 class="text-primary invoice-logo ml-0" style="font-size:22px;text-align:left;">
                                        {{invoiceData.company_name_en}}
                                        <br> {{invoiceData.company_name_cn}} <span style="font-style: italic;">(TCSP Licence No.: {{invoiceData.tcsp_licence_no}})</span>
                                    </h3>
                                </div>
                                <b-card-text class="mb-0 logo-address">
                                    {{invoiceData.company_address_en}}
                                </b-card-text>
                                <b-card-text class="mb-0 logo-address">
                                    {{invoiceData.company_address_cn}}
                                </b-card-text>
                                <b-card-text class="mb-0 logo-address">
                                    <span>Tel. 電話： {{invoiceData.company_tel}}</span><span class="ml-1">  Fax 圖文傳真：{{invoiceData.fax}}</span><span class="ml-1"> Email 電郵：{{invoiceData.company_email}}</span>
                                </b-card-text>
                            </div>
    
                            <!-- Header: Right Content 
                                        
                                        -->
                        </div>
                        <b-row>
                            <!-- Header: Left Content -->
                            <b-col cols="12" sm="7">
                                <div class="client-information">
                                    <div class="client-information-info">
                                        <h6 v-if="invoiceData.client_to" class="mb-0">{{invoiceData.client_to}}</h6>
                                        <h6 v-if="invoiceData.client_name" class="mb-0">{{invoiceData.client_name}}</h6>
                                        <h6 v-if="invoiceData.client_address1" class="mb-0">{{invoiceData.client_address1}}</h6>
                                        <h6 v-if="invoiceData.client_address2" class="mb-0">{{invoiceData.client_address2}}</h6>
                                        
                                    </div>
                                </div>
                            </b-col>
                            <!-- Header: Right Content -->
                            <b-col class="invoice-number-date mt-sm-0 mt-0 " cols="12" sm="5">
                                <div class="mb-1">
                                    <h4 class="invoice-title">
                                        DEBIT NOTE 費用通知單
                                    </h4>
                                </div>
                                <div class="d-flex align-items-center" style="margin-bottom:8px;">
                                    <span class="title">
                                                D/N No. 賬單編號  ：
                                            </span> <span class="title">{{invoiceData.invoice_number}}</span>
                                </div>
                                <div class="d-flex align-items-center" style="margin-bottom:8px;">
                                    <span class="title">
                                              Ref. No. 客戶編號  ： 
                                            </span> <span class="title">{{invoiceData.client_no}}</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <span class="title">
                                              Date <span class="title-right">日期  ：</span>
                                    </span>
                                    <span class="title">{{changeDate(invoiceData.invoice_date)}}</span>
                                </div>
                            </b-col>
    
                            <b-col cols="12" sm="12" class="mt-0 client_contact">
                                <b-row>
                                    <b-col cols="12" sm="4" style="text-align:left;">
                                        <span class="client-title">Tel. 電話 ： {{invoiceData.client_tel}}</span>
                                    </b-col>
                                    <b-col cols="12" sm="8" style="text-align:left;">
                                        <span class="client-title">Fax 傳真 ： {{invoiceData.client_fax}}</span>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <!-- Spacer -->
                    <b-card-body class="invoice-padding form-item-section" style="padding-top:0; padding-bottom:0; margin-bottom:0;">
                        <div class="invoice-table" :style="{ minHeight: controlValue.minHeight + 'cm' }">
                            <b-row >
                                <b-col cols="12" sm="9" style="text-align:left;">
                                    <span class="invoice-description-text">{{invoiceData.invoice_description}}</span>
                                </b-col>
                                <b-col cols="12" sm="3" class="invoice-item-amount">
                                    <span class="text-right">Amount(HK$)</span>
                                </b-col>
                            </b-row>
    
                            <b-row v-for="(item, index) in invoiceData.invoice_items" :key="index" ref="row" class="mt-2">
                                <b-col cols="12" sm="9" style="text-align:left;">
                                    <span class="invoice-item-text" v-html="changeNextLine(item.description)"></span>
                                </b-col>
                                <b-col cols="12" sm="3" class="invoice-item-amount">
                                    <span class="invoice-item-text text-right normal-text" v-if="item.amount!=0 || controlValue.showZero">{{getNumberFormat(item.amount)}}</span>
                                </b-col>
                            </b-row>
                        </div>
                        <div class="invoice-table-amount">
                        <div></div>
                                <span class="total-amount">{{getNumberFormat(getTotalAmount())}}</span>
                        </div>
                    </b-card-body>
                    <!-- Invoice Payment Method -->
                    <b-card-body class="invoice-padding pb-0 invoice-payment-method">
                        <b-row>
                            <!-- Col: Sales Persion -->
                            <b-col cols="12" sm="6" class="mt-md-0 mt-3 d-flex align-items-center" order="2" order-sm="1">
                                <span class="invoice-remark-text">{{invoiceData.bank_name_cn}}</span><span class="ml-3 invoice-remark-text">{{invoiceData.bank_name_en}}</span>
                            </b-col>
                            <!-- Col: Total -->
                            <b-col cols="12" sm="6" class="mt-md-6 d-flex justify-content-start" order="1" order-sm="2">
                                <span class="invoice-remark-text">銀行賬號 Bank Account：{{invoiceData.bank_account}}</span>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <!-- Spacer -->
                    <hr>
                    <b-card-body class="invoice-padding pb-0 pt-0 invoice-payment-method" v-if="invoiceData.invoice_remarks">
                        <b-row  v-for="item in invoiceData.invoice_remarks" :key="item.id" style="text-align:left;">
                            <b-col cols="12" sm="5" class="d-flex">
                                <div style="padding:0 10px 0 0; font-size:10px;">*</div>
                                <span class="invoice-remark-text">{{item.remark_cn}}</span>
                            </b-col>
    
                            <b-col cols="12" sm="7" class="d-flex">
                                <div style="padding:0 10px; 0 0">*</div>
                                <span class="invoice-remark-text">{{item.remark_en}}</span>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <hr>
                    <!-- Footer & Payment Detail -->
                    <b-card-body class="pt-0 pb-0">
                        <b-row class="invoice-spacing" style="margin-top:5px;">
                            <!-- Col: Invoice To -->
                            <b-col cols="12" sm="6" class="mb-lg-1 invoice_footer" style="text-align:left;">
                                <h6 class="mb-2">
                                    For and on behalf of<br> {{invoiceData.company_name_en}}
                                </h6>
    
                                <div class="invoice_signature">
                                    <hr>
                                    <h6>Authorised Signature</h6>
                                </div>
                            </b-col>
    
    
                            <!-- Col: Payment Details -->
                            <b-col sm="6" cols="12" class="mt-xl-0 mt-1 justify-content-end d-xl-flex d-block">
                                <div class="invoice-payment-detail" style="text-align:left;">
                                    <h6 class="mb-1">
                                        <u>Payment Details</u> <span class="ml-1">{{invoiceData.invoice_number}}</span>
                                    </h6>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td class="pr-1">
                                                    Account received
                                                </td>
                                                <td>
                                                    :
                                                </td>
                                                <td><span class="font-weight-bold"></span></td>
                                            </tr>
                                            <tr>
                                                <td class="pr-1">
                                                    Received on
                                                </td>
                                                <td>:</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td class="pr-1">
                                                    Bank / Cheque No.
                                                </td>
                                                <td>:</td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
    
        </b-row>
    </section>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardText,
} from 'bootstrap-vue'

import { mapFields } from 'vuex-map-fields'

export default {
    data() {
        return {
            month: ['Jan', 'Feb', 'March', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        };
    },
    methods: {
        changeDate(dateToChange) {
            var newDate = new Date(dateToChange)
            newDate.toLocaleString('default', { month: 'long' })
            return newDate.getDate() + ' ' + this.month[newDate.getMonth()] + ' ' + newDate.getFullYear()
        },
        getNumberFormat(amount) {
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'HKD',
                minimumFractionDigits: 0,
            });
            return formatter.format(amount);
        },
        changeNextLine(str)
        {
            return str.replaceAll('\n','<br>')
        },
        getTotalAmount(){
            var totalAmount = 0
            if(this.invoiceData.invoice_items){
                this.invoiceData.invoice_items.forEach(item => {
                    totalAmount += item.amount
                })
            }
            return totalAmount
        }


    },
    components: {
        BRow,
        BCol,
        BCard,
        BCardBody,
        BCardText,
    },
    props: {
        invoiceData: {
            type: Object,
            required: true,
        }
    },

    mounted() {
       
        // this.$nextTick(() => {
        //     setTimeout(() => {
        //         this.$emit("domRendered");
        //     }, 1000);
        // });
    },
    computed: {
        ...mapFields([
            'controlValue'
        ]),
    }
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';
.logo-wrapper h3 span {
    color: black;
    font-size: 11px;
    font-weight: 100;
}

.logo-address {
    color: black;
    font-weight: 100;
    font-size: 12px;
    font-style: italic;
    text-align: left;
}

.form-item-section {
    background-color: $product-details-bg;
    margin-bottom: 0;
    padding-bottom: 0;
}

.form-item-action-col {
    width: 27px;
}

.repeater-form {
    // overflow: hidden;
    transition: .35s height;
}

.v-select {
    &.item-selector-title,
    &.payment-selector {
        background-color: #fff;
        .dark-layout & {
            background-color: unset;
        }
    }
}

.dark-layout {
    .form-item-section {
        background-color: $theme-dark-body-bg;
        .row .border {
            background-color: $theme-dark-card-bg;
        }
    }
}

.invoice-add .invoice-preview-card .invoice-title {
    font-size: 1.6em;
    margin-right: 0;
    color:black;
}

.invoice-add .invoice-number-date .title {
    font-size: 11px;
    width: 150px;
    position: relative;
    text-align: left;
    color:black;
}

.invoice-add .invoice-number-date .title-right {
    position: absolute;
    right: 32px;
    font-size: 11px;
}

.client-information-info {
    padding: 10px 10px;
    text-align: left;
    display: flex;
    flex-direction: column;
    height: 2.7cm;
}

.client-information-info h6 {
    flex-grow: 1;
    font-size: 11px;
    font-weight: 500;
    color:black;
}

.client-information {
    width: 95%;
    height: 2.7cm;
    position: relative;
}

.client-information:before,
.client-information:after,
.client-information>:first-child:before,
.client-information>:first-child:after {
    position: absolute;
    width: 10px;
    height: 10px;
    border-color: #110f6a;
    /* or whatever colour */
    border-style: solid;
    /* or whatever style */
    content: ' ';
}



.client-information:before {
    top: 0;
    left: 0;
    border-width: 1px 0 0 1px
}

.client-information:after {
    top: 0;
    right: 0;
    border-width: 1px 1px 0 0
}

.client-information>:first-child:before {
    bottom: 0;
    right: 0;
    border-width: 0 1px 1px 0
}

.client-information>:first-child:after {
    bottom: 0;
    left: 0;
    border-width: 0 0 1px 1px
}

.client-information .form-control {
    height: 35px;
}

.invoice_footer {
    height: 3.8cm;
    position: relative;
}

.invoice_footer h6 {
    font-weight: 100;
    font-size: 0.9em;
    color: black;
}

.invoice_signature {
    position: absolute;
    bottom: 0;
    width: 60%;
}

hr {
    border-top: 0.5px solid grey;
    margin:auto;
    margin-top:5px;
    margin-bottom:5px;
    width:91.5%;
}

.invoice-payment-detail {
    width: 7.5cm;
    height: 3.4cm;
    border: 1px solid grey;
    padding: 0.36cm 0.5cm;
    font-size: 0.8em;
}

.invoice-payment-detail tr {
    height: .6cm;
}

.invoice-payment-method {
    font-size: 0.85em;
    font-weight: 500;
    padding: 0 35px 0;
}

.invoice-item-amount {
    position: relative;
    text-align: right;
}

.invoice-item-amount span {
    font-size: 0.9em;
    font-weight: 700;
}

.pdf-content {
    width: 100%;
    background: #fff;
}

.client-title {
    font-size: 11px;
}

.invoice-table {
    min-height: 12cm;
    border: 0.5px solid black;
    padding: 3px;
    position: relative;
    margin-bottom: 0;
    border-bottom:none;
}
.invoice-table-amount{
    min-height:1cm;
    border-left: 0.5px solid black;
    border-bottom: 0.5px solid black;
    border-right: 0.5px solid black;
    margin-bottom: 5px;
    position:relative;
}

.invoice-table-amount>:first-child:before,
.invoice-table-amount>:first-child:after {
    width:2.3cm;
    height:1px;
    position:absolute;
    right: 0.15cm;
    background:black;
    content: ' ';
}

.invoice-table-amount>:first-child:before{
    top:0.77cm;
}
.invoice-table-amount>:first-child:after{
    top: 0.84cm;
}


.invoice-table-amount:before{
    position:absolute;
    top:0;
    right:0;
    border-left:0.5px solid black;
    border-top: 0.5px solid black;
    width:2.8cm;
    height:100%;
    content: ' ';
}
.total-amount{
    position:absolute;
    right:5px;
    top: 0.3cm;
}

.invoice-table-amount:after{
    position:absolute;
    content: 'Total : ';
    right: 3.2cm;
    top: 0.3cm;
}

.invoice-table:before{
    position:absolute;
    top:0;
    right:0;
    border-left:0.5px solid black;
    width:2.8cm;
    height:100%;
    content: ' ';
}

.invoice-description-text {
    font-style: italic;
    font-size: 12px;
    font-weight: 700;
    color:black;
}

.invoice-item-text {
    font-size: 13px;
    font-weight: 100;
    color:black;
}

.invoice-remark-text {
    font-size: 10px;
    color: black;
}

.text-right {
    margin-right: 5px;
}

.normal-text{
    font-weight: 100 !important;
    color:black;
}

.space{
    margin-top:5px;
}

.invoice-add-wrapper {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    color: blank;
}



</style>